<template>
  <div class="tokyo_tm_home">
    <div class="home_content">
      <div class="avatar">
        <div v-if="homeDetails"
          class="image avatar_img"
            :style="{ backgroundImage: `url(${getHomeImage})` }"
        ></div>
      </div>
      <!-- End .avatar -->
      <div class="details">
        <div v-if="homeDetails">
          <h3 class="name">{{homeDetails.title}}</h3>
          <p class="job" v-html="homeDetails.description"></p>
        </div>
        <div class="tokyo_tm_button" v-if="homeDetails">
          <button class="ib-button cv" v-on:click="downloadCv()">
            <div class="cv-inside">
              <i class="fa fa-download"></i> 
              <p>Download Resume</p>
            </div>  
          </button>
        </div>
        <Social />
      </div>
      <!-- End .details -->
    </div>
  </div>
</template>

<script>
  import Social from "../components/Social";
  import getPage from '../composables/getPage';
  
  export default {
    components: {
      Social,
    },
    data() {
      return {
        homeDetails:'',
      };
    },
    async mounted(){
      const {detail,loadPage}=getPage();
      await loadPage("home");
      this.homeDetails=detail;
    },
    computed:{
      getHomeImage(){
        return this.$store.state.baseURL+this.homeDetails.image;
      }
    },
    methods:{
      downloadCv(){
        window.open(this.homeDetails.file_link, '_blank');
      }
    }
  };
</script>

<style scoped>
  .cv{
    margin-bottom:20px;
    
  }
  .cv-inside{
    display: flex;
    align-items: center;
    column-gap: 10px;
    text-align: center;
  }
</style>

